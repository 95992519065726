import React from 'react'
import IconButton from '@mui/material/IconButton'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'

const Footer = () => {
	const websiteLinks = [
		{
			label: 'Volunteer Brochure',
			link: 'https://cdn.prod.website-files.com/62f24893562f47d8766501aa/651fbfd48d3d382c93eb3939_Volunteer%20Prospectus%202023.pdf',
		},
		{
			label: 'The Ed Crisis',
			link: 'https://teachforindia.org/education-crisis',
		},
		{label: 'Our Impact', link: 'https://teachforindia.org/our-impact'},
		{label: 'FAQs', link: 'https://teachforindia.org/faqs'},
	]

	const socialLinks = [
		{
			link: 'https://www.facebook.com/teachforindia?fref=apply',
			icon: <FacebookIcon />,
		},
		{
			link: 'https://www.instagram.com/teachforindia/',
			icon: <InstagramIcon />,
		},
		{link: 'https://www.twitter.com/TeachForIndia', icon: <TwitterIcon />},
		{
			link: 'https://www.linkedin.com/company/teach-for-india',
			icon: <LinkedInIcon />,
		},
		{
			link: 'https://www.youtube.com/user/TeachForIndia',
			icon: <YouTubeIcon />,
		},
	]

	const styles = {
		footer: {
			position: 'fixed',
			bottom: 0,
			left: 0,
			right: 0,
			backgroundColor: '#f0f8ff',
			padding: '1rem',
			width: '100%',
			boxSizing: 'border-box',
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			maxWidth: '1200px',
			margin: '0 auto',
			width: '100%',
		},
		section: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			margin: '0.5rem 0',
		},
		links: {
			listStyle: 'none',
			padding: 0,
			margin: 0,
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
		},
		linkItem: {
			margin: '0.25rem 0.5rem',
		},
		linkAnchor: {
			color: '#0ec4e2',
			textDecoration: 'none',
			fontSize: '14px',
			fontWeight: 'bold',
			whiteSpace: 'nowrap',
		},
		copyright: {
			fontSize: '12px',
			color: '#333',
			textAlign: 'center',
			margin: 0,
		},
		socialLinks: {
			listStyle: 'none',
			padding: 0,
			margin: 0,
			display: 'flex',
			justifyContent: 'center',
		},
		socialLink: {
			margin: '0 0.25rem',
		},
	}

	// Media query styles
	React.useEffect(() => {
		const mediaQuery = window.matchMedia('(min-width: 768px)')

		const handleMediaQueryChange = e => {
			const container = document.getElementById('footer-container')
			const websiteLinks = document.getElementById(
				'website-links-section'
			)
			const copyright = document.getElementById('copyright-section')
			const socialLinks = document.getElementById('social-links-section')

			if (e.matches) {
				// Desktop styles
				container.style.flexDirection = 'row'
				container.style.justifyContent = 'space-between'
				websiteLinks.style.flex = '1'
				websiteLinks.style.justifyContent = 'flex-start'
				copyright.style.flex = '1'
				copyright.style.justifyContent = 'center'
				socialLinks.style.flex = '1'
				socialLinks.style.justifyContent = 'flex-end'
			} else {
				// Mobile styles
				container.style.flexDirection = 'column'
				container.style.justifyContent = 'center'
				websiteLinks.style.flex = 'none'
				websiteLinks.style.justifyContent = 'center'
				copyright.style.flex = 'none'
				copyright.style.justifyContent = 'center'
				socialLinks.style.flex = 'none'
				socialLinks.style.justifyContent = 'center'
			}
		}

		mediaQuery.addListener(handleMediaQueryChange)
		handleMediaQueryChange(mediaQuery)

		return () => mediaQuery.removeListener(handleMediaQueryChange)
	}, [])

	return (
		<footer style={styles.footer}>
			<div id="footer-container" style={styles.container}>
				<div id="website-links-section" style={styles.section}>
					<ul style={styles.links}>
						{websiteLinks.map(({label, link}) => (
							<li key={label} style={styles.linkItem}>
								<a
									href={link}
									target="_blank"
									rel="noopener noreferrer"
									style={styles.linkAnchor}
								>
									{label}
								</a>
							</li>
						))}
					</ul>
				</div>

				<div id="copyright-section" style={styles.section}>
					<p style={styles.copyright}>
						© 2023-2024 TEACH TO LEAD | ALL RIGHTS RESERVED
					</p>
				</div>

				<div id="social-links-section" style={styles.section}>
					<ul style={styles.socialLinks}>
						{socialLinks.map(({link, icon}) => (
							<li key={link} style={styles.socialLink}>
								<IconButton
									component="a"
									href={link}
									target="_blank"
									rel="noopener noreferrer"
									size="small"
									style={{color: '#0ec4e2'}}
								>
									{icon}
								</IconButton>
							</li>
						))}
					</ul>
				</div>
			</div>
		</footer>
	)
}

export default Footer
